import React, { useState, useMemo, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import * as _ from "lodash";
// Material
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import Drawer from "@material-ui/core/Drawer";
// import Tooltip from "@material-ui/core/Tooltip";
// import IconButton from "@material-ui/core/IconButton";

// Icons
import SearchIcon from "@material-ui/icons/Search";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
// Redux Actions
import { onSearchMenu } from "../../store/Menu/MenuActions";
import { showSnackbarAction } from "./../../store/Snackbar/SnackbarAction";
// Controls
import Btn from "./../Controls/Button/Button";
import IconBtn from "./../Controls/IconButton/IconButton";
import PopoverButton from "../Material/PopoverButton/PopoverButton";
// Material Component
import Modal from "./../Material/Modal/Modal";
// Components
import About from "./About/About";
import HotelDetails from "./../HotelDetails/HotelDetails";
import FilterBar from "./../FilterBar/FilterBar";
import ResturantMessageBar from "./../ResturantMessageBar";
import CartPopover from "./CartPopover/CartPopover";
// Custom Hooks
import useCheckoutForm from "../../CustomHooks/useCheckoutForm";
import useDynamicHotelData from "../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./Navbar.style.js";
import { TAKEY_APP_NAME } from "../../utils/Constants";
// import { logoutUser } from "../../store/User/UserAction";
import {
  // Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  // Menu,
  // MenuItem,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import LoginDialogCon from "../../components/Checkout/LoginDetailsForm/LoginDetailsForm";
// import { saveCheckoutForm } from "../../store/Cart/CartActions";
import Slide from "@material-ui/core/Slide";
import { AvatarMenu } from "./AvatarMenu/AvatarMenu";
import InnerLayout from "../../utils/Layouts/InnerLayout";
import ChoiceItem from "../ChoiceItem/ChoiceItem";
import OrderTypeDrawer from "../OrderTypeDrawer/OrderTypeDrawer";
import { dispatchErrorMessageAsWarning } from "../../utils/Shared";
import Categorybar from "../CategoryBar/Categorybar";
import SocialMediaLogin from "../SocialMediaLogin/SocialMediaLogin.js";
import OrderTypeModal from "../OrderTypeModal/OrderTypeModal.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getHeaderName = (str) => {
  const arr = str.split("/");
  str = arr[arr.length - 1];
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const MainHeader = ({
  openAboutModal,
  setOrderTypeModal,
  setAboutModal = () => {},
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const state = useSelector((state) => state.cart);
  const { hotelKey, hotel, tableId, isTodayHoliday, isProfileSecondPage } =
    useSelector((state) => state.hotel);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [isSearchMode, setSearchMode] = useState(false);
  const { hotelStatus, getOrderTypes, hotelOrderStatus } =
    useDynamicHotelData();
  const { formValues, setFormValues } = useCheckoutForm();
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { nextAvailableTime } = useSelector((state) => state.time);
  const { isHomePageLoaded } = useSelector((state) => state.home);
  // console.log("table idddd",tableId)

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // login noti dialog code start
  const [loginNotiOpen, setLoginNotiOpen] = useState(false);
  // dialog code ends

  // console.log("formValues,",formValues)

  const enableMobileSearch = () => {
    if (isSearchMode) {
      dispatch(onSearchMenu(""));
      setSearchPhrase("");
    }
    setSearchMode(!isSearchMode);
  };

  // useEffect(() => {
  //   if (formValues?.orderType != null) {
  //     validateCartRedirect()
  //   }
  // }, [formValues?.orderType])

  // console.log("hotelll", hotel)

  const validateCartRedirect = () => {
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    if (
      (formValues?.orderType === null || isEmptyObject(formValues)) &&
      tableId == null
    ) {
      // setDrawerOpen(true);
      setOrderTypeModal(true);
    } else if (hotel?.openingAvailable === false && hotel?.is_preorder === 0) {
      dispatchErrorMessageAsWarning({
        title: "Alert",
        message:
          "we are currently closed. Kindly revisit during our designated opening hours.",
      });
    } else if (nextAvailableTime === null && getOrderTypes == []) {
      dispatchErrorMessageAsWarning({
        title: "Warning",
        message:
          "Unfortunately, we have ceased operations for today. We kindly request that you return tomorrow.",
      });
    } else if (
      formValues?.orderType === "DELIVERY" &&
      hotel?.deliveryAvailable === false &&
      hotel?.is_preorder === 0
    ) {
      dispatchErrorMessageAsWarning({
        title: "Warning",
        message: "Delivery time is currently Finished",
      });
    } else if (
      formValues?.orderType === "PICKUP" &&
      hotel?.pickupAvailable === false &&
      hotel?.is_preorder === 0
    ) {
      dispatchErrorMessageAsWarning({
        title: "Warning",
        message: "Pickup time is currently finished",
      });
    } else {
      if (!hotelOrderStatus) {
        dispatch(showSnackbarAction(hotel?.online_inactive_reason, "warning"));
        return true;
      }
      if (state.data.length === 0) {
        dispatch(showSnackbarAction("Cart is empty", "warning"));
        return true;
      }
      // console.log('hotel keyyyy',hotelKey)
      history.push(`/${hotelKey}/cart`);
    }
  };

  const onSearch = (e) => {
    const searchF = _.debounce(
      () => dispatch(onSearchMenu(e.target.value)),
      1000
    );
    setSearchPhrase(e.target.value);
    searchF();
  };

  // console.log(
  //   "history.location.state?.fromPaymentCallback",
  //   history.location.state?.fromPaymentCallback
  // );

  const handleBackNavigation = () => {
    if (isHomePageLoaded) {
      history.goBack();
    } else {
      if (tableId) {
        history.goBack();
        return;
      }
      if (!hotel?.website) {
        history.goBack();
        return;
      }

      if (hotel?.website?.includes("http")) {
        return (window.location.href = hotel.website);
      } else window.location.href = `https://${hotel.website}`;
    }

    // if (process.env.REACT_APP_NAME === TAKEY_APP_NAME) {
    //   history.goBack();
    // } else {
    //   if (tableId) {
    //     history.goBack();
    //     return;
    //   }
    //   if (!hotel?.website) {
    //     history.goBack();
    //     return;
    //   }

    //   if (hotel?.website?.includes("http")) {
    //     return (window.location.href = hotel.website);
    //   } else window.location.href = `https://${hotel.website}`;
    // }
  };

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("loginNotification");

    if (!user && !isFirstTime) {
      setLoginNotiOpen(true);
      sessionStorage.setItem("loginNotification", "true");
    }
  }, []);

  if (isSearchMode && isMobile) {
    return (
      <div className={classes.mobileSearchContainer}>
        <IconBtn
          aria-label="search"
          component="span"
          icon={<ArrowBackIcon />}
          className={clsx(classes.iconBtn, classes.backIcon)}
          handleClick={enableMobileSearch}
        />
        <div className={clsx(classes.search, classes.grow)}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchPhrase}
            onChange={onSearch}
            placeholder="Search"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <IconBtn
        aria-label="back"
        component="span"
        icon={<ArrowBackIcon />}
        className={classes.iconBtn}
        handleClick={handleBackNavigation}
      />
      <HotelDetails desc={isMobile ? false : true} />
      <div className={classes.grow} />

      <div className={classes.sectionDesktop}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={searchPhrase}
            onChange={onSearch}
            placeholder="Search"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        <Btn
          text={`About`}
          startIcon={<StoreOutlinedIcon />}
          className={classes.btn}
          handleClick={() => setAboutModal(true)}
        />
        {state.data.length > 0 ? (
          <PopoverButton
            text={`Cart (${state.data.length})`}
            buttonStartIcon={<LocalMallOutlinedIcon />}
            buttonClass={classes.btn}
            buttonOnClick={() => validateCartRedirect()}
            disable={!hotelStatus.active || isTodayHoliday}
          >
            <CartPopover />
          </PopoverButton>
        ) : (
          <Btn
            text={`Cart`}
            handleClick={validateCartRedirect}
            startIcon={<LocalMallOutlinedIcon />}
            className={classes.btn}
          />
        )}
      </div>
      <div className={classes.sectionMobile}>
        <IconBtn
          aria-label="search"
          component="span"
          icon={<SearchIcon />}
          className={classes.iconBtn}
          handleClick={enableMobileSearch}
        />
        <IconBtn
          aria-label="about"
          component="span"
          icon={<StoreOutlinedIcon />}
          className={classes.iconBtn}
          handleClick={() => setAboutModal(true)}
        />
        {!isMobile && (
          <IconBtn
            aria-label="cart"
            component="span"
            icon={<LocalMallOutlinedIcon />}
            className={classes.iconBtn}
            handleClick={validateCartRedirect}
          >
            {state.data.length > 0 ? (
              <Typography className={classes.cartIconText} variant="caption">
                {state.data.length}
              </Typography>
            ) : null}
          </IconBtn>
        )}
      </div>
      <AvatarMenu setOpen={setOpen} />

      {/* login dialog */}
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoginDialogCon setOpen={setOpen} />
        {/* <SocialMediaLogin setOpen={setOpen} /> */}
      </Dialog>
      <Modal
        open={openAboutModal}
        customView={true}
        handleClose={() => setAboutModal(false)}
        modelContainer={classes.aboutModalContainer}
      >
        <About handleClose={() => setAboutModal(false)} />
      </Modal>
      {/* dialog */}
      {/* <Dialog
        open={loginNotiOpen}
        onClose={() => setLoginNotiOpen(false)}
        TransitionComponent={Transition}
        // keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Would you like to Login?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hi, would you like to login so you may save time on your next order
            by not having to fill out your details again?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLoginNotiOpen(false)} color="primary">
            Skip
          </Button>
          <Button
            onClick={() => {
              setLoginNotiOpen(false);
              setOpen(true);
            }}
            color="primary"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog> */}

      {drawerOpen ? (
        <OrderTypeDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

const CommonHeader = ({ pageName }) => {
  const history = useHistory();
  const classes = useStyles();

  const state = useSelector((state) => state.cart);
  const { isProfileSecondPage } = useSelector((state) => state.hotel);
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      {!isProfileSecondPage && (
        <IconBtn
          aria-label="back"
          component="span"
          icon={<ArrowBackIcon />}
          className={classes.iconBtn}
          handleClick={() => history.goBack()}
        />
      )}
      <Typography className={classes.title} variant="h1" noWrap>
        {getHeaderName(pageName)}
      </Typography>
      {["cart"].indexOf(pageName) > -1 &&
        (state.data.length ? (
          <Typography
            className={classes.totalItemsText}
            variant="caption"
            noWrap
          >
            {`${state.data.length} items`}
          </Typography>
        ) : null)}

      <div className={classes.grow} />
      <AvatarMenu setOpen={setOpen} />
      {/* login dialog */}
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoginDialogCon setOpen={setOpen} />
        {/* <SocialMediaLogin /> */}
      </Dialog>
    </React.Fragment>
  );
};

export default function Navbar({ mainHeader }) {
  const location = useLocation();
  const classes = useStyles();
  const scrollRef = useRef(null);
  const [openAboutModal, setAboutModal] = useState(false);
  // const { getTodayDeliveryIntervalSlots } = useDynamicHotelData();
  // const { tableId } = useSelector((state) => state.hotel);
  const { hotel } = useSelector((state) => state.hotel);
  const [orderTypeModal, setOrderTypeModal] = useState(false);

  const isMainHeader = useMemo(() => (mainHeader ? true : false), [mainHeader]);

  return (
    <React.Fragment>
      <div
        ref={scrollRef}
        className={clsx(
          isMainHeader ? classes.mainHeaderContainer : classes.headerContainer
        )}
      />
      <div
        className={clsx(
          isMainHeader ? classes.mainHeaderContainer : classes.headerContainer,
          classes.fixed
        )}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar
            id="navbar-toolbar"
            className={clsx("container", "p-0", classes.toolbar)}
          >
            {isMainHeader && (
              <MainHeader
                openAboutModal={openAboutModal}
                setAboutModal={setAboutModal}
                setOrderTypeModal={setOrderTypeModal}
              />
            )}
            {!isMainHeader && (
              <CommonHeader pageName={location.pathname.slice(1)} />
            )}
          </Toolbar>
          {isMainHeader && (
            <div id="filter-bar" className={classes.filterBar}>
              <FilterBar />
              {/* <Categorybar /> */}
            </div>
          )}

          <ResturantMessageBar onActionClick={() => setAboutModal(true)} />
        </AppBar>
      </div>
      <OrderTypeModal open={orderTypeModal} setOpen={setOrderTypeModal} />
    </React.Fragment>
  );
}
