import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    userSelect: "none",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid black",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  itemText: {
    fontSize: calculateFontSize(15, theme.palette.common.fontVariation),
    fontWeight: 900,
    // color: theme.palette.common.normalTitle,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),

    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemIcon: {
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  modalTitleSection: {
    marginBottom: theme.spacing(1.6),
  },
  modalTitle: {
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    color: theme?.palette?.common?.themeFontColor,
  },
}));

export default useStyles;
