import React, { useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";

// Common InnerLayout
import InnerLayout from "../../Layouts/InnerLayout";
// Components
import Navbar from "./../../../components/Navbar/Navbar";
// Styles
import useStyles from "./CommonRoute.style.js";
import { useSelector } from "react-redux";
import BasicLoader from "../../../components/Loader/BasicLoader.js";

const CommonRoute = ({ component: Component, mainHeader, ...rest }) => {
  const classes = useStyles();
  const scrollRef1 = useRef(null);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { isHotelFetchCompleted } = useSelector((state) => state.hotel);

  useEffect(() => {
    const handleScroll = () => {
      // console.log("Scrolled!");
      const currentScrollPos = scrollRef1.current.scrollTop;
      const scrollingUp = currentScrollPos > prevScrollPos;
      setIsScrollingUp(scrollingUp);
      setPrevScrollPos(currentScrollPos);
    };

    const scrollElement = scrollRef1.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [prevScrollPos]);

  return (
    <div ref={scrollRef1} className={classes.appContianer}>
      {isHotelFetchCompleted && <Navbar mainHeader={mainHeader} />}
      <Route
        {...rest}
        render={(props) => (
          <InnerLayout>
            <Component {...props} />
          </InnerLayout>
        )}
      />
    </div>
  );
};

export default CommonRoute;
