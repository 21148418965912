import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Icons
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Styles
import useStyles from "./HotelDetails.style";
import { Paper } from "@material-ui/core";
// Controls
// import IconBtn from "./../Controls/IconButton/IconButton";

const HotelDetails = ({
  desc = true,
  color = "white",
  darkTheme,
  isCart = false,
}) => {
  const classes = useStyles();
  const { hotel } = useSelector((state) => state.hotel);
  const { theme } = useSelector((state) => state?.theme);
  const hotelAddress = useMemo(() => {
    if (hotel) {
      const address = hotel.address[0];
      return `${address.streetName}, ${address.city}, ${address.country}, ${address.postalCode}`;
    }
    return "";
  }, [hotel]);
  // console.log("darkkkkkk", theme?.header_logo);
  const isBase64 = theme?.header_logo?.startsWith("data:image");
  return (
    <div className={classes.container}>
      {theme?.header_logo ? (
        // Otherwise, render the image with regular URL
        <div className={classes.headerLogoDiv}>
          <img
            src={`data:image/png;base64, ${theme?.header_logo}`}
            alt="Header Logo"
            className={classes.headerLogo}
          />
        </div>
      ) : darkTheme !== undefined ? (
        <Typography
          className={isCart ? classes.cartTitle : clsx(classes.title)}
          // style={{ color: darkTheme ? "white" : "black" }}
          variant="h1"
          // noWrap
          display="block"
        >
          {/* {APP_NAME} */}
          {hotel ? hotel.hotelName : ""}
        </Typography>
      ) : (
        <Typography
          className={isCart ? classes.cartTitle : clsx(classes.title)}
          variant="h1"
          // noWrap
          display="block"
        >
          {/* {APP_NAME} */}
          {hotel ? hotel.hotelName : ""}
        </Typography>
      )}
      {desc && isCart ? (
        <Typography
          className={clsx(
            classes.locationSpl
            // color === "black" && classes.blackColor
          )}
          // style={{ color: darkTheme ? "white" : "black" }}
          variant="h6"
        >
          <LocationOnOutlinedIcon />
          {/* <IconBtn aria-label="search" component="span" icon={<LocationOnOutlinedIcon />} className={clsx(classes.iconBtn, classes.noPadingIcon, color === 'black' && classes.blackColor)} /> */}
          {hotelAddress ? `${hotelAddress}` : ""}
        </Typography>
      ) : (
        desc && (
          <Typography className={clsx(classes.location)} variant="h6">
            <LocationOnOutlinedIcon />
            {/* <IconBtn aria-label="search" component="span" icon={<LocationOnOutlinedIcon />} className={clsx(classes.iconBtn, classes.noPadingIcon, color === 'black' && classes.blackColor)} /> */}
            {hotelAddress ? `${hotelAddress}` : ""}
          </Typography>
        )
      )}
    </div>
  );
};

export default HotelDetails;
